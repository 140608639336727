import Vue from 'vue'
import Router from 'vue-router'
import login from "../views/login";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            component: login,
            children: [
                {path: '/comfirmar/:token', component: login, params: true},
            ]
        },
        {
            path: '/confirmacao/:token',
            name: 'confirmacaoPaciente',
            component: () => import('@/views/confirmacaoPacienteView.vue'),
            params: true,
        },
        {
            path: '/registrar',
            component: () => import('@/views/register.vue'),
            children: [
                {path: '/', name: 'registrar', redirect: '/registrar/profissional'},
                {
                    path: '/registrar/profissional',
                    name: 'registrarProfissional',
                    component: () => import('@/components/cadastroProfissional.vue')
                },
            ]
        },
        {
            path: '/excluir-conta',
            component: () => import('@/views/excluirConta.vue'),
        },

        {
            path: '/esquecimento',
            component: () => import('@/views/esquecimento.vue'),

        },

        {
            path: '/novaSenha/:password',
            component: () => import('@/views/novaSenha.vue'),
            params: true,
            //
        },

        {
            path: '/atendimento',
            component: () => import('@/views/atendimentoView.vue'),
            children: [
                {path: '/', name: 'salaDeEspera', redirect: '/atendimento/salaDeEspera'},
                {path: '/atendimento/salaDeEspera/', component: () => import('@/components/salaDeEspera.vue')},
                {
                    path: '/atendimento/anamnese/',
                    name: 'anamnese',
                    component: () => import('@/components/anamnese.vue'),
                    params: true
                },
                {
                    path: '/atendimento/anexos',
                    name: 'anexos',
                    component: () => import('@/components/anexos.vue'),
                    params: true
                },
                {
                    path: '/atendimento/historico',
                    name: 'historico',
                    component: () => import('@/components/historico.vue')
                },
                {
                    path: '/atendimento/receitas',
                    name: 'receituario',
                    component: () => import('@/components/receitas.vue')
                },
                {
                    path: '/atendimento/atestados',
                    name: 'atestados',
                    component: () => import('@/components/atestados.vue')
                },
                {
                    path: '/atendimento/exames',
                    name: 'exames',
                    component: () => import('@/components/exames.vue')
                },
                {
                    path: '/atendimento/agenda',
                    name: 'agendaAtendimento',
                    component: () => import('@/components/agendaAtendimento.vue')
                }
            ]
        },
        {
            path: '/agenda',
            name: 'agenda',
            component: () => import('@/views/agendamentoView.vue'),
        },
        {
            path: '/pacientes',
            name: 'pacientes',
            component: () => import('@/views/pacientesView.vue')
        },
        {
            path: '/consultas',
            name: 'minhasConsultas',
            component: () => import('@/views/consultasView.vue')
        },
        {
            path: '/menu',
            name: 'menu',
            component: () => import('@/components/menu.vue')
        },
        {
            path: '/perfil',
            name: 'perfil',
            component: () => import('@/views/perfilView.vue'),
            children: [
                {
                    path: '/perfil/profissional',
                    name: 'M',
                    component: () => import('@/components/perfilMedico.vue')
                },
                /*                {
                                    path: '/perfil/paciente',
                                    name: 'P',
                                    component: () => import('@/components/perfilPaciente.vue')
                                }*/
            ]
        },
        {
            path: '/teste',
            name: 'teste',
            component: () => import('@/components/teste.vue')
        },
        {
            path: '/teleorientacao',
            component: () => import('@/components/Teleorientacao/teleorientacaoView.vue'),
            children: [
                {
                    path: '/teleorientacao/:id',
                    name: 'teleorientacaoAberta',
                    component: () => import('@/components/Teleorientacao/teleorientacao.vue'),
                    params: true
                }
            ]
        },
        {
            path: '/configuracoes',
            name: 'configuracoes',
            component: () => import('@/views/configuracoesView.vue'),
            children: [
                {path: '/configuracoes/', component: () => import('@/components/configuracoesForm.vue')},
                {path: '/configuracoes/index', component: () => import('@/components/configuracoesForm.vue')},
                {path: '/configuracoes/especialidades', component: () => import('@/views/especialidadesView.vue')},
                {
                    path: '/configuracoes/vias_de_administracao',
                    component: () => import('@/components/viasDeAdministracao.vue')
                },
                {
                    path: '/configuracoes/certificado',
                    component: () => import('@/components/configuracoesCertificado.vue')
                },
                {path: '/configuracoes/pagamento', component: () => import('@/components/configuracoesPagamento.vue')},
                {path: '/configuracoes/conselhos', component: () => import('@/views/conselhosView.vue')},
                {
                    path: '/configuracoes/procedimentos',
                    name: 'procedimentos',
                    component: () => import('@/components/procedimentosView.vue')
                },
                {path: '/configuracoes/paciente', component: () => import('@/components/configuracoesPaciente.vue')},
                {path: '/configuracoes/admin', component: () => import('@/components/configuracoesAdmin.vue')},
                {path: '/admin/', name:'admin',component: () => import('@/components/Admin/adminView.vue')},
                {path: '/configuracoes/excluir_conta', name:'excluir_conta',component: () => import('@/views/configuracoesContaView.vue')}

            ]
        },
    ]
})
