<template>
    <v-footer color="primary" absolute :app="true" height="54px" padless class="z-index-footer">
        <v-card flat tile color="#0089E1" width="100%" >
            <v-card-text class="white--text darken-1 text-center">
                © 2023 Copyright:
                <a href="https://intersistemas.com.br/" class="white--text darken-1" target="_blank"
                   style="text-decoration: none">
                    Intersistemas
                </a>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: "footer-component",
}
</script>

<style scoped>
.z-index-footer {
    z-index: 1000;
}

</style>