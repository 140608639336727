import Vue from 'vue';
import App from './App.vue';
import router from './router';
import api from "./plugins/api";
import VueAxios from 'vue-axios';
import '@mdi/font/css/materialdesignicons.css';
import vuetify from './plugins/vuetify';
import {VueEditor} from "vue2-editor";
import VueTheMask from 'vue-the-mask';
import WebRTC from 'vue-webrtc';
import Vuetify from 'vuetify/lib';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);


// import DisableAutocomplete from 'vue-disable-autocomplete';

// Vue.use(DisableAutocomplete);

Vue.use(Vuetify)
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    locale: 'pt-BR',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})
// Translation provided by Vuetify (typescript)
import pt from 'vuetify/lib/locale/pt'
export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'en',
    },
})

const ImageZoom = require('vue-image-zoomer').default;

Vue.component('image-zoom', ImageZoom);

Vue.use(VueAxios, api)
Vue.use(VueEditor);
Vue.use(VueTheMask);
Vue.use(WebRTC);
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    components: {App},
    vuetify,
    template: '<App/>'
})
