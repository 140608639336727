<template>
    <div>
        <v-main>
            <v-container >
                <slot></slot>
            </v-container>
        </v-main>
        <footer-component/>
    </div>
</template>

<script>
    import FooterComponent from "./footer";
    export default {
        name: "loginLayout",
        components: {FooterComponent}
    }
</script>

<style scoped>

</style>