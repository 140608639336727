<template>
    <v-app id="app">
        <router-view></router-view>
    </v-app>
</template>

<script>

export default {
    name: 'app',
    components: {},
    created() {
        this.$vuetify.lang.current = 'pt'
    }
}
</script>
<style scoped>
    #app {
        background: rgba(237,237,237,1);
        font-family: 'Calibri Light', sans-serif !important;
        font-size: 16px;
    }
</style>
