<template>
    <login-layout>
        <v-form ref="fromLogin" v-model="valid" lazy-validation>
            <v-row justify="center" style="margin-top: 3rem">
                <v-img :src="require('@/assets/images/marca_tauk.png')" alt="Tauk" max-width="200" contain></v-img>
            </v-row>
            <v-row justify="center" style="margin-top: 3rem">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="text-center" elevation="2">
                        <v-card-title>
                            <h1 class="display-1">Entrar</h1>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field type="email" v-model="username" label="Email" @keydown.enter="login"
                                          :rules="emailRules"></v-text-field>
                            <v-text-field type="password" v-model="password" label="Senha" @keydown.enter="login"
                                          :rules="passwordRules"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="white--text" block @click="login" style="background-color: #70D41E;">
                                Entrar
                            </v-btn>
                        </v-card-actions>
                        <v-card-subtitle>
                            <v-btn to="/registrar" color="#0089E1" text>
                                <v-icon left>mdi-account-plus</v-icon>
                                Criar conta
                            </v-btn>
                            <v-btn to="/esquecimento" color="#0089E1" text>
                                Esqueci Minha Senha
                            </v-btn>
                        </v-card-subtitle>

                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </login-layout>
</template>

<script>
    import api from "../plugins/api";
    import Swal from "sweetalert2";
    import LoginLayout from "../components/loginLayout";

    export default {
        name: "login",
        components: {LoginLayout},
        data() {
            return {
                valid: true,
                username: '',
                password: '',
                emailRules: [
                    v => !!v || 'E-mail é necessário',
                    v => /.+@.+/.test(v) || 'E-mail deve ser válido',
                ],
                passwordRules: [
                    v => !!v || 'Senha é necessária',
                    v => v.length >= 6 || 'Senha deve ter no mínimo 6 caracteres',
                ]
            }
        },
        methods: {
            async login() {
                if (!this.$refs.fromLogin.validate()) {
                    return
                }
                try {
                    const res = await api.post('/api/login', {username: this.username, password: this.password});
                    let dados = res.data;
                    localStorage.setItem('token', dados.access_token);
                    this.$router.push('/atendimento');
                } catch (err) {
                    if (err.response) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: err.response.data,
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            timer: 3000,
                            timerProgressBar: true,
                            text: 'Falha na conexão com o servidor',
                        });
                    }
                }
            }
        },
        async created() {
            let token = this.$route.params.token
            if (token) {
                console.log(token)
                try {
                    const res = await api.get('/api/cadastro/confirmarCadastro', {params: {token: token}});
                    Swal.fire({
                        icon: 'success',
                        title: 'Cadastro confirmado',
                        text: res.data.message,
                    });
                } catch (err) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response.data.message,
                    });
                }
            }
        }
    }
    //
</script>

<style scoped>

</style>